// src/components/NotFound.js
import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Helmet>
          <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
          <meta name="Fashion-editor.gr" />
          <meta
            name="description"
            content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
          />
          <meta
            name="keywords"
            content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
          />
      </Helmet>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, pink.400, pink.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you're looking for does not seem to exist.
      </Text>
      <Button
        colorScheme="pink"
        bgGradient="linear(to-r, pink.400, pink.500, pink.600)"
        color="white"
        variant="solid"
        as={RouterLink}
        to="/"
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
