import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { Helmet } from 'react-helmet-async';

function PrivacyPolicy() {
  return (
    <Box p={5}>
        <Helmet>
          <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
          <meta name="Fashion-editor.gr" />
          <meta
            name="description"
            content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
          />
          <meta
            name="keywords"
            content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
          />
      </Helmet>
      <Heading as="h1" size="lg" mb={4}>
        Πολιτική Απορρήτου
      </Heading>

      <UnorderedList spacing={4}>
        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Ι. Απόρρητες Πληροφορίες
          </Heading>
          <Text>
            Όλες οι πληροφορίες, οι οποίες αναφέρονται σε προσωπικά στοιχεία των χρηστών, διασφαλίζονται ως απόρρητες. Η Εταιρεία έχει υιοθετήσει διαδικασίες, οι οποίες προφυλάσσουν τα προσωπικά δεδομένα, που οι χρήστες παρέχουν μέσω της ιστοσελίδας.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            ΙΙ. Μη αποκάλυψη Στοιχείων
          </Heading>
          <Text>
            Η Εταιρεία δεν αποκαλύπτει τα στοιχεία των χρηστών της ιστοσελίδας και των συναλλαγών, εκτός αν υπάρχει έγγραφη εξουσιοδότηση από αυτούς ή αυτό επιβάλλεται από δικαστική απόφαση ή απόφαση άλλης δημόσιας αρχής. Μόνο εξουσιοδοτημένοι υπάλληλοι έχουν πρόσβαση στις πληροφορίες των συναλλαγών, όποτε αυτό κρίνεται αναγκαίο. Ο χρήστης μπορεί να ζητήσει οποτεδήποτε όσα στοιχεία τηρούνται για τον ίδιο, καθώς επίσης και τη διόρθωσή τους, σε περίπτωση που τεκμηριώνεται η ύπαρξη λάθους.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            ΙΙΙ. Εγγραφή σε Ενημερωτικά Δελτία
          </Heading>
          <Text>
            Η Εταιρεία παρέχει τη δυνατότητα εγγραφής για λήψη ενημερωτικών δελτίων σε όσους επιθυμούν. Η εγγραφή συνιστά ρητή αποδοχή και συναίνεση εκ μέρους του χρήστη στη μελλοντική ενημέρωσή του σχετικά με διαφημιστικές ή προωθητικές ενέργειες (marketing) προϊόντων και υπηρεσιών μέσω της παρούσας ιστοσελίδας www.smartphonesaccessories.gr της διοργανώτριας εταιρείας ή συνεργαζόμενων, με αυτήν, εταιρειών, μέσω ηλεκτρονικών μηνυμάτων. Τα στοιχεία που αποθηκεύονται για την ενημέρωση των συμμετεχόντων θα τηρούνται πάντοτε σύμφωνα με το Ν.2472/1997, το Ν. Ν.3471/2006 και τις Αποφάσεις και Οδηγίες της Αρχής Προστασίας Δεδομένων Προσωπικού Χαρακτήρα. Οι συμμετέχοντες διατηρούν το δικαίωμα να ζητήσουν οποτεδήποτε και χωρίς επιβάρυνση την επιβεβαίωση, τροποποίηση ή διαγραφή των στοιχείων τους και την εξαίρεσή τους από τη λήψη ηλεκτρονικών μηνυμάτων, επικοινωνώντας με τη διοργανώτρια εταιρεία.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            ΙV. Έλεγχος Πρόσβασης
          </Heading>
          <Text>
            Η πρόσβαση στα συστήματα της Εταιρίας (servers) ελέγχεται από firewall, το οποίο επιτρέπει τη χρήση συγκεκριμένων υπηρεσιών, απαγορεύοντας παράλληλα την πρόσβαση σε συστήματα και βάσεις δεδομένων με απόρρητα στοιχεία και πληροφορίες της Εταιρείας.
          </Text>
        </ListItem>
      </UnorderedList>
    </Box>
  );
}

export default PrivacyPolicy;
