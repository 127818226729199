// src/components/Filters.js
import React, { useState } from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, Stack, Skeleton, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input, Text } from '@chakra-ui/react';

const Filters = ({ displayedProducts, availableBrands, availableSizes, availableColors, selectedFilters, handleFilterChange, isLoading }) => {
  const [brandSearch, setBrandSearch] = useState('');
  const [sizeSearch, setSizeSearch] = useState('');
  const [colorSearch, setColorSearch] = useState('');


  // console.log('availableProducts: '+displayedProducts.length);
  // console.log(displayedProducts);
  // console.log('availableBrands: '+availableBrands.length);
  // console.log(availableBrands);
  // console.log('availableSizes: '+availableSizes.length);
  // console.log('availableColors: '+availableColors.length);

  const filteredBrands = availableBrands.filter(brand =>
    brand.name.toLowerCase().includes(brandSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_brand' &&
        attr.options.map(option => option.toLowerCase()).includes(brand.name.toLowerCase())
      )
    )
  );

  const filteredSizes = availableSizes.filter(size =>
    size.name.toLowerCase().includes(sizeSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_size' &&
        attr.options.map(option => option.toLowerCase()).includes(size.name.toLowerCase())
      )
    )
  );

  const filteredColors = availableColors.filter(color =>
    color.name.toLowerCase().includes(colorSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_color' &&
        attr.options.map(option => option.toLowerCase()).includes(color.name.toLowerCase())
      )
    )
  );

  return (
    <Box p="6" borderWidth="1px" borderRadius="lg">
      <Box flex="1" display={{ base: 'none', md: 'block' }}>
        <Heading mb={4}  size='lg' fontSize='24px'>Φίλτρα</Heading>
      </Box>
      <Accordion defaultIndex={[0,1]} allowMultiple>
        {/* Price Filter */}
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Τιμή
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Stack direction="row" spacing="2">
              <NumberInput
                name="minPrice"
                value={isNaN(selectedFilters.minPrice) ? 0 : selectedFilters.minPrice}
                onChange={(valueString) => handleFilterChange({ target: { name: 'minPrice', value: valueString } })}
                min={0}
              >
                <NumberInputField placeholder="Min" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <NumberInput
                name="maxPrice"
                value={isNaN(selectedFilters.maxPrice) ? 1000 : selectedFilters.maxPrice}
                onChange={(valueString) => handleFilterChange({ target: { name: 'maxPrice', value: valueString } })}
                min={0}
                max={10000}
              >
                <NumberInputField placeholder="Max" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack>
          </AccordionPanel>
        </AccordionItem>

        {/* Brand Filter */}
        {filteredBrands.length > 0 && (
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Μάρκα
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {filteredBrands.length > 5 && (
                <Input
                  placeholder="Αναζήτηση..."
                  mb={4}
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                />
              )}
              <Stack maxHeight="150px" overflowY="auto">
                {isLoading ? (
                  [...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" />
                  ))
                ) : (
                  filteredBrands.map(brand => (
                    <Checkbox
                      key={brand.id}
                      name="brands"
                      value={brand.id}
                      onChange={handleFilterChange}
                      isChecked={selectedFilters.brands.includes(brand.id.toString())}
                    >
                      <Text>{brand.name}</Text>
                    </Checkbox>
                  ))
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )}

        {/* Size Filter */}
        {filteredSizes.length > 0 && (
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Μέγεθος
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {filteredSizes.length > 5 && (
                <Input
                  placeholder="Αναζήτηση..."
                  mb={4}
                  value={sizeSearch}
                  onChange={(e) => setSizeSearch(e.target.value)}
                />
              )}
              <Stack maxHeight="150px" overflowY="auto">
                {isLoading ? (
                  [...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" />
                  ))
                ) : (
                  filteredSizes.map(size => (
                    <Checkbox
                      key={size.id}
                      name="sizes"
                      value={size.id}
                      onChange={handleFilterChange}
                      isChecked={selectedFilters.sizes.includes(size.id.toString())}
                    >
                      <Text>{size.name}</Text>
                    </Checkbox>
                  ))
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )}

        {/* Color Filter */}
        {filteredColors.length > 0 && (
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Χρώμα
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {filteredColors.length > 5 && (
                <Input
                  placeholder="Αναζήτηση..."
                  mb={4}
                  value={colorSearch}
                  onChange={(e) => setColorSearch(e.target.value)}
                />
              )}
              <Stack maxHeight="150px" overflowY="auto">
                {isLoading ? (
                  [...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" />
                  ))
                ) : (
                  filteredColors.map(color => (
                    <Checkbox
                      key={color.id}
                      name="colors"
                      value={color.id}
                      onChange={handleFilterChange}
                      isChecked={selectedFilters.colors.includes(color.id.toString())}
                    >
                      <Text>{color.name}</Text>
                    </Checkbox>
                  ))
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Box>
  );
};

export default Filters;