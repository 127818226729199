import React, { useEffect } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CategoryPage from './pages/CategoryPage';
import SearchResults from './pages/SearchResults';
import ProductDetail from './pages/ProductDetail';
import FAQ from './pages/FAQ';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound'; // Import the NotFound component
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';


// Track page views when the route changes
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview for the current path
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function App() {
  // Initialize Google Analytics once on app load
  useEffect(() => {
    ReactGA.initialize('G-1CDJTNX8NN'); // Replace with your Measurement ID
  }, []);

  // Track each pageview
  usePageTracking();

  return (
    <HelmetProvider>
      <Box>
        <Header />
        <Container justifyContent="space-between" alignItems="center" maxW="1200px" mx="auto">
          <Box as="main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product-category/:slug" element={<CategoryPage />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/product/:slug" element={<ProductDetail />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
            </Routes>
          </Box>
        </Container>
        <Footer />
      </Box>
    </HelmetProvider>
  );
}

export default App;
