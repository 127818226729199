import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { Helmet } from 'react-helmet-async';

function FAQ() {
  return (
    <Box p={5}>
      <Helmet>
          <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
          <meta name="Fashion-editor.gr" />
          <meta
            name="description"
            content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
          />
          <meta
            name="keywords"
            content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
          />
      </Helmet>
      <Heading as="h1" size="lg" mb={4}>
        Συχνές Ερωτήσεις
      </Heading>

      <UnorderedList spacing={4}>
        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Το fashion-editor.gr δεν είναι ηλεκτρονικό κατάστημα
          </Heading>
          <Text>
            Το fashion-editor.gr δεν είναι ηλεκτρονικό κατάστημα, δεν πωλεί απευθείας δικά του προϊόντα. Απλά συγκεντρώνει στις σελίδες του τα προϊόντα από άλλα ηλεκτρονικά καταστήματα, έτσι ώστε να διευκολύνει την αναζήτησή σας και να σας δίνει τη δυνατότητα να έχετε μια ολοκληρωμένη αντίληψη της αγοράς.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Πως μπορώ να αγοράσω ένα προϊόν;
          </Heading>
          <Text>
            Κάντε click πάνω στο προϊόν και θα μεταφερθείτε στο ηλεκτρονικό κατάστημα που πωλεί το προϊόν. Μπορείτε να το αγοράσετε από εκεί ή να ρωτήσετε για περισσότερες πληροφορίες. Αν συναντήσετε κάποιο πρόβλημα, επικοινωνήστε μαζί μας, μήπως και μπορέσουμε να σας βοηθήσουμε εμείς αντί του ηλεκτρονικού καταστήματος.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Γιατί ένα προϊόν δεν είναι διαθέσιμο;
          </Heading>
          <Text>
            Πολλά προϊόντα εξαντλούνται από τα ηλεκτρονικά καταστήματα που τα πωλούν. Αν γνωρίζετε από ποιο κατάστημα προέρχεται το προϊόν, επικοινωνήστε μαζί τους. Προϊόντα που δεν είναι διαθέσιμα μένουν τουλάχιστον 6 μήνες στην βάση δεδομένων μας πριν διαγραφούν.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Διαφορά τιμής στο ηλεκτρονικό κατάστημα;
          </Heading>
          <Text>
            Ο κατάλογός μας ενημερώνεται καθημερινά με βάση τα στοιχεία που μας αποστέλλουν τα καταστήματα. Μπορεί να βρείτε προϊόν με διαφορετική τιμή, μέχρι να γίνει η επόμενη ενημέρωση.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Γιατί δεν υπάρχει το χρώμα;
          </Heading>
          <Text>
            Το χρώμα είναι μια πληροφορία που λαμβάνουμε από τα καταστήματα. Πολλά δεν το παρέχουν και για αυτό το φίλτρο του χρώματος μπορεί να μην είναι 100% ακριβές. Είμαστε σε επικοινωνία για τη βελτίωση αυτής της πληροφορίας.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Γιατί δεν υπάρχει πληροφορία για τα μεγέθη;
          </Heading>
          <Text>
            Ελάχιστα καταστήματα παρέχουν αυτή την πληροφορία. Επιπλέον, τα μεγέθη διαφέρουν ανάλογα με τα μεγεθολόγια. Εργαζόμαστε για να λύσουμε αυτό το ζήτημα.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Καλύτερος διαχωρισμός παιδικών σε αγορίστικα και κοριτσίστικα
          </Heading>
          <Text>
            Χρησιμοποιούμε αυτή την πληροφορία σαν φίλτρο όπου είναι διαθέσιμη. Ωστόσο, πολλά καταστήματα κατηγοριοποιούν τα προϊόντα ως 'παιδικά' γενικώς και αυτό δυσκολεύει τον διαχωρισμό.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Τι είναι τα Αγαπημένα;
          </Heading>
          <Text>
            Τα Αγαπημένα είναι μια κατηγοριοποίηση προϊόντων που σας αρέσουν, ανεξάρτητα αν θέλετε να τα αγοράσετε. Περιλαμβάνει προϊόντα που προσδιορίζουν το προσωπικό σας γούστο.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            Πως απενεργοποιώ ενημερώσεις για κουπόνια και προσφορές;
          </Heading>
          <Text>
            Για να απενεργοποιήσετε τις ενημερώσεις για κουπόνια & προσφορές, κάντε κλικ στον σύνδεσμο σε ένα από τα emails που λαμβάνετε.
          </Text>
        </ListItem>
      </UnorderedList>
    </Box>
  );
}

export default FAQ;
