import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import HeroBanner from '../components/HeroBanner';
import FeaturedProductCarousel from '../components/FeaturedProductCarousel';
import TopCategoriesCarousel from '../components/TopCategoriesCarousel';
import BannerCarousel from '../components/BannerCarousel';
import api from '../woocommerce';
import { Helmet } from 'react-helmet-async';
import debounce from 'lodash.debounce';

const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [isLoadingFeaturedProducts, setIsLoadingFeaturedProducts] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // URL for categories data
  const fileUrl = 'https://fashion-editor.gr/site-feeds/categories.json';

  // Memoized function to fetch categories
  const fetchAllCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const fileData = await response.json(); // Assuming the file is JSON
      const allCategories = fileData.filter(category => category.parent === 0);
      setCategories(allCategories);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }, []); // Ensure fetchAllCategories does not change on each render

  // Memoized function to fetch featured products
  const fetchFeaturedProducts = useCallback(
    debounce(async () => {
      try {
        const cachedProducts = localStorage.getItem('featuredProducts');
        if (cachedProducts) {
          setFeaturedProducts(JSON.parse(cachedProducts));
          setIsLoadingFeaturedProducts(false);
        } else {
          const response = await api.get('/products', {
            params: {
              featured: true,
              per_page: 4,
              fields: 'id,name,slug,images,price,regular_price,sale_price'
            }
          });

          const products = response.data.length > 0 ? response.data : await fetchLatestProducts();
          setFeaturedProducts(products);
          localStorage.setItem('featuredProducts', JSON.stringify(products)); // Cache result
          setIsLoadingFeaturedProducts(false);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setIsLoadingFeaturedProducts(false);
      }
    }, 500),
    [] // Empty dependency array ensures this function is only created once
  );

  // Function to fetch latest products as a fallback
  const fetchLatestProducts = async () => {
    const response = await api.get('/products', {
      params: {
        orderby: 'date',
        order: 'desc',
        per_page: 4,
        fields: 'id,name,slug,images,price,regular_price,sale_price'
      }
    });
    return response.data;
  };

  // Fetch categories and featured products on initial render
  useEffect(() => {
    fetchAllCategories();
    fetchFeaturedProducts();
  }, [fetchAllCategories, fetchFeaturedProducts]); // Use memoized functions as dependencies

  // Generate JSON-LD for Schema.org
  const generateJsonLd = () => {
    const productsLd = featuredProducts.map(product => ({
      "@type": "Product",
      "name": product.name,
      "image": product.images[0]?.src || '',
      "description": product.short_description,
      "sku": product.sku || '',
      "brand": {
        "@type": "Brand",
        "name": product.attributes.find(attr => attr.slug.toLowerCase() === 'pa_brand')?.options[0] || ''
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": 'EUR',
        "price": product.price,
        "availability": "https://schema.org/InStock",
        "url": `${window.location.origin}/product/${product.slug}`
      }
    }));

    const categoriesLd = categories.map(category => ({
      "@type": "Category",
      "name": category.name,
      "url": `${window.location.origin}/product-category/${category.slug}`,
      "image": category.image?.src || ''
    }));

    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Fashion-editor.gr",
      "description": "Welcome to the homepage of Fashion-editor.gr where you can find featured products and top categories.",
      "url": 'https://fashion-editor.gr',
      "potentialAction": {
        "@type": "SearchAction",
        "target": `${window.location.origin}/search?query={search_term_string}`,
        "query-input": "required name=search_term_string"
      },
      "mainEntity": [
        ...productsLd,
        ...categoriesLd
      ]
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    );
  };

  return (
    <Box>
      <Helmet>
        <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
        <meta name="Fashion-editor.gr" />
        <meta
          name="description"
          content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
        />
        <meta
          name="keywords"
          content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
        />
        {/* Sitelinks Searchbox */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://fashion-editor.gr",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "${window.location.origin}/search?query={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      {generateJsonLd()}
      <HeroBanner />
      <Heading size="lg" mt="8" mb="4">Featured Products</Heading>
      <FeaturedProductCarousel products={featuredProducts} isLoading={isLoadingFeaturedProducts} />
      <Heading size="lg" mt="8" mb="4">Top Categories</Heading>
      <TopCategoriesCarousel categories={categories} isLoading={isLoading} />
      <Heading size="lg" mt="8" mb="4">Check Also</Heading>
      <BannerCarousel />
      {/* <Heading size="lg" mt="8" mb="4">Partners Offers</Heading>
      <OffersDisplay /> */}
    </Box>
  );
};

export default Home;