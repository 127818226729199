// src/components/HeroBanner.js
import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import banner from '../assets/hero-banner.webp'; // Import the banner image

const HeroBanner = () => {
  return (
    <Box
      width="100vw"  // Full width of the viewport
      height="50vw" // Set the desired height for the banner
      bgImage={`url(${banner})`} // Reference the imported banner image
      bgPosition="center" // Center the background image
      bgRepeat="no-repeat" // Do not repeat the background image
      bgSize="cover" // Cover the entire box area with the background image
      display="flex" // Flexbox to align children elements
      alignItems="center" // Vertically center the children
      justifyContent="center" // Horizontally center the children
      color="white" // Text color
      position="relative" // Position the box relative to its normal position
      left="50%" // Offset the box to the center
      transform="translateX(-50%)" // Correct the offset to perfectly align with the viewport
      mx="auto" // Ensure it's centered horizontally in the container
    >
      <Box textAlign="center">
        <Text fontSize="4xl" fontWeight="bold">Welcome to Our Store</Text>
        <Text fontSize="xl" mt="4">Find the best products at unbeatable prices.</Text>
        <Button mt="8" colorScheme="pink" size="lg">Shop Now</Button>
      </Box>
    </Box>
  );
};

export default HeroBanner;
