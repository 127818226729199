import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { Helmet } from 'react-helmet-async';

function TermsAndConditions() {
  return (
    <Box p={5}>
      <Helmet>
          <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
          <meta name="Fashion-editor.gr" />
          <meta
            name="description"
            content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
          />
          <meta
            name="keywords"
            content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
          />
      </Helmet>
      <Heading as="h1" size="lg" mb={4}>
        Όροι και Προϋποθέσεις
      </Heading>

      <Text mb={4}>
        Οι παρόντες όροι και προϋποθέσεις διέπουν τη χρήση της ιστοσελίδας fashion-editor.gr. Με την πρόσβαση και χρήση της ιστοσελίδας, αποδέχεστε πλήρως και χωρίς καμία επιφύλαξη τους όρους που ακολουθούν.
      </Text>

      <UnorderedList spacing={4}>
        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            1. Περιεχόμενο Ιστοσελίδας
          </Heading>
          <Text>
            Το fashion-editor.gr δεν είναι ηλεκτρονικό κατάστημα και δεν πωλεί προϊόντα απευθείας. Σκοπός της ιστοσελίδας είναι η συγκέντρωση και παρουσίαση προϊόντων από διάφορα ηλεκτρονικά καταστήματα για την καλύτερη εξυπηρέτηση των χρηστών.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            2. Περιορισμός Ευθύνης
          </Heading>
          <Text>
            Το fashion-editor.gr δεν φέρει καμία ευθύνη για την ακρίβεια, πληρότητα ή διαθεσιμότητα των πληροφοριών και των προϊόντων που παρέχονται από τα συνεργαζόμενα ηλεκτρονικά καταστήματα. Κάθε αγορά γίνεται απευθείας μέσω των καταστημάτων, τα οποία ευθύνονται αποκλειστικά για την ποιότητα και την αποστολή των προϊόντων.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            3. Πνευματικά Δικαιώματα
          </Heading>
          <Text>
            Όλο το περιεχόμενο της ιστοσελίδας, όπως κείμενα, εικόνες, γραφικά, λογότυπα, ανήκουν στο fashion-editor.gr ή στους αντίστοιχους παρόχους περιεχομένου. Απαγορεύεται η αναπαραγωγή, τροποποίηση ή αναδημοσίευση του περιεχομένου χωρίς την προηγούμενη έγγραφη άδεια.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            4. Προσωπικά Δεδομένα
          </Heading>
          <Text>
            Το fashion-editor.gr συμμορφώνεται πλήρως με τις διατάξεις του Ν.2472/1997 και Ν.3471/2006 σχετικά με την προστασία των προσωπικών δεδομένων. Τα στοιχεία που παρέχονται από τους χρήστες χρησιμοποιούνται αποκλειστικά για τη διευκόλυνση της χρήσης της ιστοσελίδας και την παροχή ενημερώσεων, με τη συγκατάθεση των χρηστών.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            5. Αλλαγές στους Όρους Χρήσης
          </Heading>
          <Text>
            Το fashion-editor.gr διατηρεί το δικαίωμα να τροποποιεί τους παρόντες όρους και προϋποθέσεις οποιαδήποτε στιγμή χωρίς προηγούμενη ειδοποίηση. Οι χρήστες θα ενημερώνονται για τις αλλαγές μέσω της ιστοσελίδας.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            6. Σύνδεσμοι σε Τρίτους Ιστότοπους
          </Heading>
          <Text>
            Το fashion-editor.gr περιέχει συνδέσμους προς άλλους ιστότοπους, για τους οποίους δεν φέρει ευθύνη. Η χρήση αυτών των ιστότοπων γίνεται με δική σας ευθύνη και σύμφωνα με τους όρους και τις προϋποθέσεις αυτών των ιστοτόπων.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            7. Εφαρμοστέο Δίκαιο
          </Heading>
          <Text>
            Οι παρόντες όροι διέπονται από το Ελληνικό δίκαιο. Οποιαδήποτε διαφορά προκύψει σχετικά με τη χρήση της ιστοσελίδας θα επιλύεται από τα αρμόδια δικαστήρια της Αθήνας.
          </Text>
        </ListItem>

        <ListItem>
          <Heading as="h2" size="md" mb={2}>
            8. Επικοινωνία
          </Heading>
          <Text>
            Για οποιαδήποτε απορία ή πρόβλημα σχετικά με τη χρήση της ιστοσελίδας, μπορείτε να επικοινωνήσετε μαζί μας μέσω email στο info@fashion-editor.gr.
          </Text>
        </ListItem>
      </UnorderedList>
    </Box>
  );
}

export default TermsAndConditions;
