// src/components/Footer.js
import React from 'react';
import { Box, Text, Link, Flex } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="pink.700" color="white" py="4" mt="8">
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Text fontSize="sm" textAlign="center">&copy; {new Date().getFullYear()} Fashion Editor. All rights reserved.</Text>
        <Flex mt="2" direction="row" align="center">
          <Link href="/faq" mx="2" fontSize="sm" _hover={{ color: "gray.300" }}>Συχνές Ερωτήσεις</Link>
          <Link href="/terms-and-conditions" mx="2" fontSize="sm" _hover={{ color: "gray.300" }}>Όροι και Προϋποθέσεις</Link>
          <Link href="/privacy-policy" mx="2" fontSize="sm" _hover={{ color: "gray.300" }}>Πολιτική Απορρήτου</Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
