// src/components/ProductDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Image, Text, Heading, Stack, SimpleGrid, Button, Flex, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Skeleton, SkeletonText } from '@chakra-ui/react';
import api from '../woocommerce';
import logo from '../assets/fe-logo.png';
import { Helmet } from 'react-helmet-async';

const ProductDetail = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [fallbackProducts, setFallbackProducts] = useState([]);
  const [breadcrumbCategories, setBreadcrumbCategories] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingBreadcrumb, setIsLoadingBreadcrumb] = useState(true);

  useEffect(() => {
    api.get(`/products?slug=${slug}`)
      .then(response => {
        const productData = response.data[0];
        setProduct(productData);
  
        if (productData.categories.length > 0) {
          buildCategoryTree(productData.categories[0]);
        }
  
        if (productData.related_ids.length > 0) {
          api.get(`/products?include=${productData.related_ids.join(',')}&per_page=4`)
            .then(response => {
              if (response.data && response.data.length > 0) {
                setRelatedProducts(response.data);
              } else {
                // Call fallback if related products are empty
                fetchFallbackProducts(productData.categories[0].id);
              }
            })
            .catch(error => {
              console.error('Error fetching related products', error);
              // Fetch fallback products in case of an error
              fetchFallbackProducts(productData.categories[0].id);
            });
        } else {
          // Call fallback if there are no related IDs
          fetchFallbackProducts(productData.categories[0].id);
        }
      })
      .catch(error => {
        console.error('Error fetching product', error);
      })
      .finally(() => {
        setIsLoadingProduct(false);
      });
  }, [slug]);
  

  const buildCategoryTree = async (category) => {
    const categories = [];
    let currentCategory = category;
    let hasParent = true;

    const response = await api.get(`/products/categories/${currentCategory.id}`);
    currentCategory = response.data;
    categories.unshift(currentCategory);

    if(currentCategory.parent !== 0){
      while(hasParent === true){
        const response = await api.get(`/products/categories/${currentCategory.parent}`);
        currentCategory = response.data;
        categories.unshift(currentCategory);

        if(currentCategory.parent !== 0){
          hasParent = true;
        } else {
          hasParent = false;
        }
      }
    }

    setBreadcrumbCategories(categories);
    setIsLoadingBreadcrumb(false);
  };

  const fetchFallbackProducts = (categoryId) => {
    api.get('/products', {
      params: {
        category: categoryId,
        per_page: 4
      }
    })
    .then(response => {
      setFallbackProducts(response.data);
    })
    .catch(error => {
      console.error('Error fetching fallback products', error);
    });
  };

  const generateJsonLd = () => {
    if (!product) return null;
    
    const imageUrl = product.images && product.images[0] ? product.images[0].src : '';
    const brandName = product.attributes?.find(attr => attr.name.toLowerCase() === 'brand')?.options[0] || '';
    const priceCurrency = 'EUR';
  
    // Function to strip HTML tags from the description
    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    };
  
    const jsonLd = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": product.name || '',
      "image": imageUrl,
      "description": stripHtmlTags(product.short_description) || '',  // Sanitized description
      "sku": product.sku || '',
      "brand": {
        "@type": "Brand",
        "name": brandName,
      },
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": priceCurrency,
        "price": product.price || '',
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "priceValidUntil": new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 30 days from now
        "seller": {
          "@type": "Organization",
          "name": "Fashion-editor.gr"
        }
      },
      // Aggregate Rating - only if the product has ratings
      ...(product.rating_count > 0 && {
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": product.average_rating || "0", // Assuming average_rating field exists
          "reviewCount": product.rating_count || "0",   // Assuming rating_count field exists
        }
      }),
      // Reviews (assuming product.reviews is an array of review objects)
      "review": product.reviews?.map(review => ({
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": review.reviewer || "Anonymous" // Assuming a review.reviewer field exists
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": review.rating || "0", // Assuming a review.rating field exists
          "bestRating": "5"
        },
        "reviewBody": review.review || "No review text", // Assuming a review.review field exists
        "datePublished": review.date_created || "N/A",  // Assuming review.date_created exists
      })) || [],
    };
  
    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    );
  };
  
  
  // Breadcrumb JSON-LD
  const generateBreadcrumbJsonLd = () => {
    if (!breadcrumbCategories.length || !product) return null;

    const breadcrumbList = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbCategories.map((category, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": category.name,
        "item": `${window.location.origin}/product-category/${category.slug}`
      })).concat({
        "@type": "ListItem",
        "position": breadcrumbCategories.length + 1,
        "name": product.name,
        "item": window.location.href
      })
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbList)}
      </script>
    );
  };

  // Website Search JSON-LD
  const generateWebsiteSearchJsonLd = () => {
    const searchJsonLd = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": window.location.origin+"/#website",
      "url": window.location.origin,
      "name": "Fashion-editor.gr",
      "description": "Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr",
      "potentialAction": [
        {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": window.location.origin+"/?s={search_term_string}"
          },
          "query-input": {
            "@type": "PropertyValueSpecification",
            "valueRequired": true,
            "valueName": "search_term_string"
          }
        }
      ],
      "inLanguage": "el"
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(searchJsonLd)}
      </script>
    );
  };

  return (
    <Box p="6" maxW="1320px" mx="auto">
      <Helmet>
        {/* Title Tag */}
        <title>{product ? `${product.name} - Fashion-editor.gr` : 'Loading... - Fashion-editor.gr'}</title>

        {/* Meta Description */}
        <meta name="description" content={product ? `Απόκτησε τώρα ${product.name} online από το Fashion-editor.gr.`: 'Loading...'} />

        {/* Canonical URL */}
        <link rel="canonical" href={window.location.href} />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={product ? `${product.name}` : 'Loading...'} />
        <meta property="og:description" content={product ? `${product.short_description}` : 'Loading...'} />
        <meta 
          property="og:image" 
          content={product?.images?.[0]?.src || logo} 
        />
        <meta property="og:url" content={window.location.href} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product ? `${product.name}` : 'Loading...'} />
        <meta name="twitter:description" ontent={product ? `${product.short_description}` : 'Loading...'} />
        <meta 
          name="twitter:image" 
          content={product?.images?.[0]?.src || logo} 
        />

        {generateJsonLd()}
        {generateBreadcrumbJsonLd()}
        {generateWebsiteSearchJsonLd()}

      </Helmet>
      
      
      {/* Breadcrumb */}
      {isLoadingBreadcrumb ? (
        <Skeleton height="20px" mb="6" />
      ) : (
        <Box
          mb="6"
          overflowX="auto" // Enable horizontal scrolling
          whiteSpace="nowrap" // Prevent items from wrapping
        >
          <Breadcrumb display="inline-block">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumbCategories.map(category => (
              <BreadcrumbItem key={category.id}>
                <BreadcrumbLink as={RouterLink} to={`/product-category/${category.slug}`}>
                  {category.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            {product && (
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{product.name}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>
      )}

      {/* Main Product Info */}
      <Flex direction={['column', 'row']} gap={6}>
        <Box flex="1">
          {isLoadingProduct ? (
            <Skeleton height="300px" />
          ) : (
            <Image src={product.images[0]?.large_src} alt={product.name} />
          )}
        </Box>
        <Box flex="2">
          <Stack spacing="4">
            {isLoadingProduct ? (
              <>
                <Skeleton height="30px" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" />
                <Skeleton height="20px" width="100px" />
              </>
            ) : (
              <>
                <Heading as="h1" size="lg">{product.name}</Heading>
                <Text fontSize="xl">
                  {parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                    <>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        {product.regular_price} €
                      </span>{' '}
                      <span>{product.sale_price} €</span>
                    </>
                  ) : (
                    <span>{product.regular_price} €</span>
                  )}
                </Text>

                {product.meta_data.length > 0 && (
                  <Box>
                    <Stack spacing="2">
                      {product.meta_data
                        .filter(meta => meta.key == 'availability') // Filter out 'yith_product_brand'
                        .map(meta => (
                          <Text key={meta.id}>
                            <strong>{meta.key === 'availability' ? 'Διαθεσιμότητα' : meta.key}:</strong> {meta.value}
                          </Text>
                        ))
                      }
                    </Stack>
                  </Box>
                )}
                <Text dangerouslySetInnerHTML={{ __html: product.short_description }} />
                {product.external_url && (
                  <Button as="a" href={product.external_url} target="_blank" colorScheme="pink">
                    {product.button_text || 'Buy Now'}
                  </Button>
                )}

                {/* Additional Information Section */}
                <Box mt="8">
                  <Heading size="md" mb="4">Επιπλεόν Χαρακτηριστικά</Heading>
                  <Stack spacing="4">
                    <Text><strong>SKU:</strong> {product.sku || 'N/A'}</Text>
                    {product.attributes.length > 0 && (
                      <Box>
                        <Stack spacing="2">
                          {product.attributes.map(attr => (
                            <Text key={attr.id}><strong>{attr.name === 'Brand' ? 'Μάρκα' : attr.name}:</strong> {attr.options.join(', ')}</Text>
                          ))}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Flex>

      {/* Related Products Section */}
      {isLoadingProduct ? (
        <Box mt="10">
          <Skeleton height="30px" width="200px" mb="6" />
          <SimpleGrid columns={[1, 2, 3, 4]} spacing="40px">
            {[...Array(4)].map((_, index) => (
              <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                <Skeleton height="200px" />
                <Box p="6">
                  <SkeletonText mt="4" noOfLines={2} spacing="4" />
                  <Skeleton height="40px" width="100px" mt="4" />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <>
          {product.related_ids.length > 0 ? (
            <Box mt="10">
              <Heading size="lg" mb="4">Δες Επίσης</Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing="12px">
                {relatedProducts.map(relatedProduct => (
                  <Box key={relatedProduct.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <RouterLink to={`/product/${relatedProduct.slug}`}>
                      <Image src={relatedProduct.images[0]?.medium_src} alt={relatedProduct.name}  width="100%" 
                        height="auto" 
                        objectFit="cover"
                        loading="lazy"
                        _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }} />
                    </RouterLink>
                    
                    <Box p="6">
                      <Stack spacing="3">
                        {/* Display the first product category name above the product title in lowercase */}
                        {relatedProduct.categories && relatedProduct.categories.length > 0 && (
                          <Text fontSize="sm" textTransform="uppercase">
                            {relatedProduct.categories[0].name.toLowerCase()}
                          </Text>
                        )}
                        <Text fontSize="xl">
                          {parseFloat(relatedProduct.sale_price) < parseFloat(relatedProduct.regular_price) ? (
                            <>
                              <span style={{ textDecoration: 'line-through', color: 'red' }}>
                                {relatedProduct.regular_price} €
                              </span>{' '}
                              <span>{relatedProduct.sale_price} €</span>
                            </>
                          ) : (
                            <span>{relatedProduct.regular_price} €</span>
                          )}
                        </Text>
                        <Button as={RouterLink} to={`/product/${relatedProduct.slug}`} colorScheme="pink">
                          Περισσότερα
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          ) : (
            <Box mt="10">
              <Heading size="lg" mb="4">Δείτε Επίσης στην ίδια κατηγορία</Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing="12px">
                {fallbackProducts.map(product => (
                  <Box key={product.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <RouterLink to={`/product/${product.slug}`}>
                      <Image src={product.images[0]?.medium_src} alt={product.name}  width="100%" 
                        height="auto" 
                        objectFit="cover"
                        loading="lazy"
                        _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }} />
                    </RouterLink>
                    <Box p="6">
                      <Stack spacing="3">
                        {/* Display the first product category name above the product title in lowercase */}
                        {product.categories && product.categories.length > 0 && (
                          <Text fontSize="sm" textTransform="uppercase">
                            {product.categories[0].name.toLowerCase()}
                          </Text>
                        )}
                        <Text fontWeight="bold" fontSize="xl">{product.name}</Text>
                        <Text fontSize="xl">
                          {parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                            <>
                              <span style={{ textDecoration: 'line-through', color: 'red' }}>
                                {product.regular_price} €
                              </span>{' '}
                              <span>{product.sale_price} €</span>
                            </>
                          ) : (
                            <span>{product.regular_price} €</span>
                          )}
                        </Text>
                        <Button as={RouterLink} to={`/product/${product.slug}`} colorScheme="pink">
                          Περισσότερα
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ProductDetail;