import React, { useEffect, useState } from 'react';
import { Box, Flex, Skeleton, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react'; // Chakra UI Accordion for mobile
import { Link as RouterLink } from 'react-router-dom';
// import api from '../woocommerce';
import { Menubar } from 'primereact/menubar';  // PrimeReact Menubar for desktop
import 'primereact/resources/themes/md-light-indigo/theme.css';  // PrimeReact theme
import 'primereact/resources/primereact.min.css';          // PrimeReact core styles
import '../assets/MegaMenu.css';  // Custom menu styles

const fileUrl = 'https://fashion-editor.gr/site-feeds/categories.json'; // Example URL to a JSON file


const HeaderNavigation = ({ isMobile = false, onClose }) => {  // Accept onClose as prop
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch categories
  // const fetchAllCategories = async () => {
  //   let allCategories = [];
  //   try {
  //     const firstResponse = await api.get('custom-api/v1/categories');
  //     allCategories = firstResponse.data;
  //     return allCategories;  
  //   } catch (err) {
  //     throw new Error('Error fetching categories:', err);
  //   }
  // };
  
  // useEffect(() => {
  //   const loadCategories = async () => {
  //     setIsLoading(true);
  //     try {
  //       const allCategories = await fetchAllCategories();
  //       setCategories(allCategories);
  //       setIsLoading(false);
  //     } catch (err) {
  //       console.error(err);
  //       setError(err.message);
  //       setIsLoading(false);
  //     }
  //   };
  //   loadCategories();
  // }, []);

  // if (error) {
  //   return <p>Error loading categories: {error}</p>;
  // }

  useEffect(() => {
    // Function to fetch file and store data
    const fetchAllCategories = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const fileData = await response.json(); // Assuming the file is JSON. Adjust if needed.
        setCategories(fileData);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchAllCategories();
  }, []);

  if (error) return <p>Error: {error}</p>;

  const buildMenuBarItems = () => {
    return categories
      .filter(parentCategory => parentCategory.parent === 0) // Top-level categories
      .map(parentCategory => {
        const childItems = categories
          .filter(childCategory => childCategory.parent === parentCategory.id)
          .map(childCategory => ({
            label: childCategory.name,
            items: categories
              .filter(grandChild => grandChild.parent === childCategory.id)
              .map(grandChild => ({
                label: grandChild.name,
                command: () => { window.location.href = `/product-category/${grandChild.slug}`; }
              }))
          }));

        return {
          label: parentCategory.name,
          items: childItems.length > 0 ? childItems : [{ label: 'No items available' }]
        };
      });
  };

  // Build the mobile accordion menu
  const buildMobileMenuItems = () => {
    return categories
      .filter(parentCategory => parentCategory.parent === 0) // Top-level categories
      .map(parentCategory => (
        <AccordionItem key={parentCategory.id}>
          <AccordionButton  _hover={{ bg: "gray.300", color: "black" }}>
            <Box flex="1" textAlign="left">
              {parentCategory.name}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {categories
              .filter(childCategory => childCategory.parent === parentCategory.id)
              .map(childCategory => (
                <AccordionItem key={childCategory.id}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {childCategory.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {categories
                      .filter(grandChild => grandChild.parent === childCategory.id)
                      .map(grandChild => (
                        <Text
                          key={grandChild.id}
                          as={RouterLink}
                          to={`/product-category/${grandChild.slug}`}
                          display="block"
                          pl={6}
                          onClick={onClose}  // Trigger onClose when grandchild is selected
                        >
                          {grandChild.name}
                        </Text>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </AccordionPanel>
        </AccordionItem>
      ));
  };

  return (
    <Flex
      as="nav"
      direction={isMobile ? 'column' : 'row'}
      bg={isMobile ? 'pink.700' : 'transparent'}
      color={isMobile ? 'white' : 'inherit'}
      padding="10px"
      justifyContent={isMobile ? 'flex-start' : 'space-around'}
      position="relative"
    >
      {isLoading ? (
        [...Array(4)].map((_, index) => (
          <Skeleton key={index} height="20px" width="100px" mr="5" />
        ))
      ) : (
        <Box width="100%">
          {/* Render Menubar for desktop */}
          {!isMobile && <Menubar model={buildMenuBarItems()} />}

          {/* Render Accordion Menu for mobile */}
          {isMobile && (
            <Accordion allowMultiple>
              {buildMobileMenuItems()}
            </Accordion>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default HeaderNavigation;
