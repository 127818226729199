// src/woocommerce.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://productfeed.fashion-editor.gr/wp-json/wc/v3', // Replace with your WooCommerce site URL
  auth: {
    username: 'ck_c0460f8a84cabb4a9c9befb42257636c3a0d4ba2',  // Replace with your Consumer Key
    password: 'cs_aa72cfa8226ab3ce80e9e281f300dca4916bb4e0' // Replace with your Consumer Secret
  }
});

export default api;
